'use strict';

$(function () {
    $('a[href*="#"]').on('click',function(e){
        var t= $(this.hash);
        t=t.length&&t||$('[name='+this.hash.slice(1)+']');
        if(t.length){
            var tOffset=t.offset().top;
            $('html,body').animate({scrollTop:tOffset-40},'fast');
            e.preventDefault();
        }
    });
    $('#year').text(new Date().getYear() + 1900);
});

// problem: embed tag at the bottom of the page from jumping the focus to the bottom on page load
// solution:
//   hide then show iframe/embed https://stackoverflow.com/a/7148477/3192102
//   execute when scroll reaches element https://stackoverflow.com/a/48748388/3192102
$(document).ready(function(){
    $(window).scroll(function(){
        var x = $("#resume-header").offset();
        var height1 = $("#resume-header").outerHeight();
        var y = document.documentElement.scrollTop;
        var z = (x.top + height1) - y;
        // console.log(z, $(window).height())
        if(z < $(window).height()){
             $("#pdf").css({"position":"static","visibility":"visible", "top":"0"});
        }
    });
});